
import { defineComponent, PropType, ref } from "vue";
import Alert from "@/components/Ui/Alert.vue";
import Spinner from "@/components/Ui/Spinner.vue";
import { copyToClipboard } from "@/utils";

type Item = {
  title: string;
  value: string;
  titleStyle?: string;
  valueStyle?: string;
  copy: boolean;
};

export default defineComponent({
  name: "DesktopInvoiceSuccess",
  components: {
    Alert,
    Spinner,
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },
  setup() {
    const copied = ref<boolean>(false);

    const copy = (txt: string, copy: boolean) => {
      if (copy) {
        copyToClipboard(txt.includes("|") ? txt.split("|")[0] : txt);
        copied.value = true;
      }
    };

    return {
      copied,
      copy,
    };
  },
});
